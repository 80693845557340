<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Rutas - Nuevo</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>
               
                <b-col md="12">
                  <b-form-group label="Nombre:">
                    <b-form-input type="text" v-model="route.name"></b-form-input>
                    <small v-if="errors.name"  class="form-text text-danger" >Ingrese un nombre</small>
                  </b-form-group>
                </b-col>


                <b-col md="12">
                  <b-form-group label="Descripción:">
                    <b-form-textarea type="text" rows="5" v-model="route.description"></b-form-textarea>
                    <small v-if="errors.description" class="form-text text-danger" >Ingrese una descripción</small>
                  </b-form-group>
                </b-col>

              

                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
// components
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  components:{
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'Route',
      role: 2,
      route: {
          id_route:'',
          name:'',
          description:'',
          price_pen:'0.00',
          price_usd:'0.00',
          state:1,
      },
      buses:[],
      errors: {
        id_bus: false,
        name: false,
        description: false,
        departure_time: false,
        price_pen: false,
        price_usd: false,
      },
      validate: false,
    };
  },
  mounted() {
   
  },
  methods: {
    AddRoute,
    Validate,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};



function AddRoute() {
  let me = this;
  let url = me.url_base + "route/add";
  let data = me.route;
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.route.name = '';
        me.route.description = '';
        me.route.price_pen = '0.00';
        me.route.price_usd = '0.00';
        me.route.state = 1;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {

  this.errors.name = this.route.name.length == 0 ? true : false;


  if (this.errors.name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
 

  let me = this;
  Swal.fire({
    title: "Esta seguro de registrar la ruta ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.AddRoute();
    }
  });
}
</script>
